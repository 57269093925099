<template>
    <div class="price xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>产品价格</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>产品价格</a></p>
                </div>
            </div>
        </div>
        <div class="price-box">
            <el-row :gutter="20">
                <el-col :span="6">
                    <div class="price-item">
                        <h2>套餐一</h2>
                        <div class="price-num">
                            <p>￥888.00/<span>年</span></p>
                        </div>
                        <ul>
                            <li><span>20个</span> 医生账号</li>
                            <li><span>5个</span> 管理账号</li>
                            <li><span>免费</span> 域名使用</li>
                            <li><span>专业版</span> 疾病库</li>
                            <li><span>免费试用</span> 转诊功能</li>
                        </ul>
                        <div class="contact-us">
                            联系客服购买
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="price-item">
                        <img style="position: absolute;left: 20px;top:-2px;" src="../../assets/img/jian_tag.png" alt="">
                        <h2>套餐二</h2>
                        <div class="price-num">
                            <p>￥1888.00/<span>年</span></p>
                        </div>
                        <ul>
                            <li><span>20个</span> 医生账号</li>
                            <li><span>5个</span> 管理账号</li>
                            <li><span>免费</span> 域名使用</li>
                            <li><span>专业版</span> 疾病库</li>
                            <li><span>免费试用</span> 转诊功能</li>
                        </ul>
                        <div class="contact-us">
                            联系客服购买
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="price-item">
                        <h2>套餐三</h2>
                        <div class="price-num">
                            <p>￥2888.00/<span>年</span></p>
                        </div>
                        <ul>
                            <li><span>20个</span> 医生账号</li>
                            <li><span>5个</span> 管理账号</li>
                            <li><span>免费</span> 域名使用</li>
                            <li><span>专业版</span> 疾病库</li>
                            <li><span>免费试用</span> 转诊功能</li>
                        </ul>
                        <div class="contact-us">
                            联系客服购买
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="price-item">
                        <h2>套餐四</h2>
                        <div class="price-num">
                            <p>￥3888.00/<span>年</span></p>
                        </div>
                        <ul>
                            <li><span>20个</span> 医生账号</li>
                            <li><span>5个</span> 管理账号</li>
                            <li><span>免费</span> 域名使用</li>
                            <li><span>专业版</span> 疾病库</li>
                            <li><span>免费试用</span> 转诊功能</li>
                        </ul>
                        <div class="contact-us">
                            联系客服购买
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    // import rtBar from "../../components/right";
    export default {
        name: "productPrice",
        components: {
            ftCom,
            nhCom,
            // rtBar
        },
    }
</script>

<style scoped>
    .price{
        background: #f7f7f7;
    }
    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg")center no-repeat;
    }

    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
/*----------*/
    .price-box{
        width: 1100px;
        margin: 100px auto;
    }
    .price-box .price-item{
        position: relative;
        background-color: #FFFFFF;
        border: 1px solid #f5f1e6;
        border-radius: 3px;
        box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
        cursor: pointer;
    }
    .price-box .price-item:hover h2{
        background: #333333;
        color: #FFFFFF;
    }
    .price-box .price-item:hover .contact-us{
        background: #333333;
        color: #FFFFFF;
    }
    .price-box .price-item h2{
        font-size: 30px;
        color: #333333;
        text-align: center;
        line-height: 2.5;
    }
    .price-box .price-item .price-num{
        height: 50px;
        line-height: 50px;
        background-color: #e5d8ad;
    }
    .price-box .price-item .price-num p{
        font-size: 20px;
        color: #333333;
        font-weight: bold;
        text-align: center;
    }
    .price-box .price-item .price-num p span{
        color: #666666;
    }
    .price-box .price-item ul{
        margin: 15px 0;
    }
    .price-box .price-item ul li{
        font-size: 18px;
        color: #333333;
        text-align: center;
        line-height: 48px;
    }
    .price-box .price-item ul li span{
        color: #808080;
    }
    .price-box .price-item .contact-us{
        margin: 0 auto 30px;
        width: 200px;
        height: 50px;
        line-height: 50px;
        border: 1px solid #e5d8ad;
        font-size: 16px;
        color: #c79b0a;
        text-align: center;
    }
</style>
